import Vue from "vue";
import Router from "vue-router";
import appConfig from "../../src/app.config";
import { api } from "@/state/services";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      name: "Home",
      path: "/",
      component: () => import("./views/home"),
      meta: {
        title: appConfig.title + " › Home",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Login",
      path: "/login",
      component: () => import("./views/login"),
      meta: {
        title: appConfig.title + " › Login",
        authRequired: false,
        keepAlive: false,
      },
    },
    {
      name: "Logging in",
      path: "/login/token/:token",
      component: () => import("./views/login-in"),
      meta: {
        title: appConfig.title + " › Logging in",
        authRequired: false,
        keepAlive: false,
      },
    },
    {
      name: "Sair",
      path: "/logout",
      redirect: "/login",
    },
    {
      name: "Cadastre-se",
      path: "/register",
      component: () => import("./views/register"),
      meta: {
        title: appConfig.title + " › Cadastre-se",
        authRequired: false,
        keepAlive: false,
      },
    },
    {
      name: "Cadastre-se › Patrocinador",
      path: "/register/:sponsor",
      component: () => import("./views/register"),
      meta: {
        title: appConfig.title + " › Cadastre-se › Patrocinador",
        authRequired: false,
        keepAlive: false,
      },
    },
    {
      name: "Esqueceu sua senha?",
      path: "/password-forgot",
      component: () => import("./views/password-forgot"),
      meta: {
        title: appConfig.title + " › Esqueceu sua senha?",
        authRequired: false,
        keepAlive: false,
      },
    },
    {
      name: "Alterar senha",
      path: "/password-change",
      component: () => import("./views/password-change"),
      meta: {
        title: appConfig.title + " › Alterar senha",
        authRequired: false,
        keepAlive: false,
      },
    },
    {
      name: "Minha Conta",
      path: "/account",
      component: () => import("./views/account/account"),
      meta: {
        title: appConfig.title + " › Minha Conta",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Minha Assinatura",
      path: "/account/membership",
      component: () => import("./views/account/membership"),
      meta: {
        title: appConfig.title + " › Minha Assinatura",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Minha Senha",
      path: "/account/password",
      component: () => import("./views/account/password"),
      meta: {
        title: appConfig.title + " › Minha Senha",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "PIX",
      path: "/account/pix",
      component: () => import("./views/account/pix"),
      meta: {
        title: appConfig.title + " › PIX",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      },
    },
    {
      name: "Rede › Indicados",
      path: "/network/sponsored",
      component: () => import("./views/network/sponsored"),
      meta: {
        title: appConfig.title + " › Rede › Indicados",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Rede › Equipe",
      path: "/network/team",
      component: () => import("./views/network/team"),
      meta: {
        title: appConfig.title + " › Rede › Equipe",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Relatórios › Indicação",
      path: "/reports/indicacao",
      component: () => import("./views/reports/indicacao"),
      meta: {
        title: appConfig.title + " › Relatórios › Indicação",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Downloads",
      path: "/downloads",
      component: () => import("./views/downloads"),
      meta: {
        title: appConfig.title + " › Downloads",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Meus Tabuleiros",
      path: "/boards",
      component: () => import("./views/boards/listing"),
      meta: {
        title: appConfig.title + " › Tabuleiros",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Tabuleiro",
      path: "/boards/:id",
      component: () => import("./views/boards/view"),
      meta: {
        title: appConfig.title + " › Tabuleiro",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Tabuleiros › Posição",
      path: "/boards/position/:id",
      component: () => import("./views/boards/position"),
      meta: {
        title: appConfig.title + " › Tabuleiros › Posição",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "FAQ",
      path: "/faq",
      component: () => import("./views/faq"),
      meta: {
        title: appConfig.title + " › FAQ",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Tickets",
      path: "/tickets",
      component: () => import("./views/tickets"),
      meta: {
        title: appConfig.title + " › Tickets",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Tickets › Ver",
      path: "/tickets/:id",
      component: () => import("./views/tickets-view"),
      meta: {
        title: appConfig.title + " › Tickets › Ver",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "404",
      path: "/404",
      component: () => import("./views/404"),
      meta: {
        title: appConfig.title + " › 404",
        authRequired: false,
        keepAlive: false,
      },
    },
    {
      path: "*",
      redirect: "/register*",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");

  if (to.meta.authRequired && !loggedIn) {
    return next("/login");
  }

  if (to.meta.authRequired && loggedIn) {
    api
      .get("user")
      .then((response) => {
        if (response.data && response.data.status == "success") {
          if (
            response.data.order &&
            response.data.order.id &&
            to.name != "Loja › Pedido"
          ) {
            return next("/store/order/" + response.data.order.id);
          } else if (
            response.data.user.membership &&
            response.data.user.membership.status &&
            response.data.user.membership.status == 'pending' &&
            response.data.user.membership.order &&
            response.data.user.membership.order.id
          ) {
            return next("/store/order/" + response.data.user.membership.order.id);
          }
        } else {
          return next("/login");
        }
      })
      .catch(error => {
        if (error) {
          return next("/login");
        }
      });
  }

  document.title = to.meta.title;
  next();
});

export default router;
